<template>
    <div class="mt-5" v-if="isLoadingQuiz" style="min-height:300px;">
      <LoaderMain />
      </div>
    <div v-else
      class="row position-relative m-0 p-0 w-100 big-div-quiz"
      style="z-index: 100"
      :style="getPageDesign()"
    >
      <!-- BackGroundDiv start-->
      <div
        class="bg lazyload"
        :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
        v-if="pageDesign.pageStyles.imageAlign == 'no'"
        :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
  :style="
  getbackgroundImage(
  IsImageAdded,
  pageDesign.imageBlock,
  pageDesign.pageStyles.imageAlign , isDesktopView
  ) + getBgDivStyles()
  "
      ></div>
      <!-- BackGroundDiv end-->
      <div v-if="showAlert" class="AlertContainer">
        <div
          class="alert text-center fade show text-white alert-text"
          role="alert"
          style="background: #f27367; width: fit-content"
        >
          <span
            ><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
            {{ showAlertText }}</span
          >
        </div>
      </div>
      <!-- Image Align Left -->
      <div
        class="px-0"
        v-show="showLeftImage"
        :class="[
          {
            'col-12 col-lg-12 col-xs-12': !isDesktopView,
            'image-division col-6': isDesktopView,
          },
        ]"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div :class="isDesktopView ? 'image-division' : ''">
          <div :style="height100(isDesktopView)">
            <LazyImage
            :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) +
                getImageRotation(pageDesign.imageBlock.style) +
                getImageOpacity(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
              alt
            />
          </div>
        </div>
      </div>
  
      <!-- Main Section -->
      <div
        :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
        :style="
          getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
          getScrollCSS(
            pageDesign.pageStyles,
            isDesktopView,
            pageDesign.imageBlock.style,
            pageDesign.pageType
          ) +
          calculateContentWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive,
            pageDesign.pageStyles
          )
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
          "
        >
          <div style="overflow: hidden scroll" class="h-100">
           
            <div
              v-for="(pro, ind) in pageDesign.blocksArray"
              :key="'preview' + ind"
              class=""
            >
              <div
                id="preview"
                :style="getHeightWidth(pro.style)"
                class="content"
                style="position: relative"
              >
                <div
                  v-if="pro.type == 'text'"
                  :style="
                    getColorCss(pro.style) +
                    getPaddingCss(pro.style) +
                    getMarginCss(pro.style, isDesktopView) +
                    getColorWithOpacity(pro.style) +
                    getTextRotate(pro.style) +
                    getLineHeight(pro.style) +
                    getTextAlign(pro.style) +
                    getFontStyle(pro.style,isDesktopView)
                  "
                 
                  v-html="getTextValue(pro.content)"
                ></div>
               
  
               
                <div
                  v-if="pro.type == 'option'"
                 
                > 
                  <div >
                    <!-- Checkbox Options -->
                    <div
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getTextAlign(pro.style)
                      "
                    >
                      <div class="" v-if="isIkeaClientTagQuestion">
                        <p class="m-0 p-0" style="text-align: right">
                          {{ IkeaClientCounterValue }}
                        </p>
                      </div>
                      <div v-if="pro.optionSetting.optionType === 'image'">
                        <div class="row">
                          <div
                            v-for="(checkbox, index) in getOptionsArr"
                            :key="index"
                            class="d-flex flex-column"
                            :style="
                              getDivAlignmentStyle(pro.style.textAlign) +
                              getSpaceBetweenRow(pro.style)
                            "
                            :class="
                              isDesktopView
                                ? `col-${12 / pro.OptionsPerRow}`
                                : `col-${12 / pro.OptionsPerMobile}`
                            "
                          >
                            <!-- If Image is not selected -->
                            <div
                              v-if="!checkbox.image"
                              :style="
                                getImageHeightWidth(pro.style, isDesktopView)
                              "
                              class="ImageInputBlock position-relative"
                              @click="checkSelectedImage(checkbox)"
                            >
                              <LazyImage
                                :src="'/images/image_placeholder.png'"
                                alt=""
                                style="cursor: pointer; height: 100%; width: 100%"
                                @click="checkSelectedImage(checkbox)"
                                :style="getBorderRadius(pro.style)"
                              />
                              <div
                                class="SelectedImageStyle"
                                v-if="IsSelectedImageExist(checkbox)"
                                :style="getSelectedImageIconStyles(pro.style)"
                              >
                                <i
                                  class="fa fa-check-circle fa-2x"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <!-- If Image is selected -->
                            <div
                              v-else
                              :style="
                                getImageHeightWidth(pro.style, isDesktopView)
                              "
                              class="ImageInputBlock position-relative"
                              @click="checkSelectedImage(checkbox)"
                            >
                              <LazyImage
                                :src="ImageSrc(checkbox.image)"
                                alt=""
                                @click="checkSelectedImage(checkbox)"
                                style="cursor: pointer; height: 100%; width: 100%"
                                :style="getBorderRadius(pro.style)"
                              />
                              <div
                                class="SelectedImageStyle"
                                v-if="IsSelectedImageExist(checkbox)"
                                :style="getSelectedImageIconStyles(pro.style)"
                              >
                                <i
                                  class="fa fa-check-circle fa-2x"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
  
                            <div>
                              <p
                                class="m-0 p-0 mt-2"
                                :style="
                                  getColorCss(pro.style) +
                                  getColorWithOpacity(pro.style) +
                                  getFontStyle(pro.style)
                                "
                              >
                                {{ checkbox.value }}
                              </p>
                            </div>
                            <div
                              class="counter-parent mx-2 pt-2"
                              v-if="
                                isIkeaClientCounterQuestion &&
                                checkSelectedOptionForCounter(checkbox)
                              "
                            >
                              <div
                                class="d-flex justify-content-center align-items-center"
                              >
                                <button
                                  @click="
                                    incrementCounter(checkbox.id, 'decrement')
                                  "
                                  :style="[{ backgroundColor: '#CDE4E3' }]"
                                  style="
                                    width: 40px !important;
                                    height: 40px !important;
                                    border-radius: 50% !important;
                                    max-width: 40px !important;
                                    max-height: 40px !important;
                                    min-width: 40px !important;
                                    min-height: 40px !important;
                                    border: none;
                                  "
                                >
                                  -
                                </button>
                                <!-- <input type="text" style="width: 30px; border:none;"> -->
                                <span style="width: 30px; border: none">{{
                                  currentCounterValue(checkbox.id)
                                }}</span>
                                <button
                                  @click="
                                    incrementCounter(checkbox.id, 'increment')
                                  "
                                  :style="[{ backgroundColor: '#CDE4E3' }]"
                                  style="
                                    width: 40px !important;
                                    height: 40px !important;
                                    border-radius: 50% !important;
                                    max-width: 40px !important;
                                    max-height: 40px !important;
                                    min-width: 40px !important;
                                    min-height: 40px !important;
                                    border: none;
                                  "
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
               
              </div>
            </div>
          </div>
  
          <!-- Multiple Ans Back Button -->
          <div>
            <div
             
              :style="getHeightWidth(StaticButtonElement.style)"
              class="content"
            >
              <div class="w-100">
                <div
                  class="d-flex"
                  :style="
                    getBackNextButtonPosition(
                      StaticButtonElement,
                      CurrentPage.value
                    )
                  "
                >
                  <button
                    style="z-index: 1000"
                    :style="
                      getCommonButtonCSS(
                        StaticButtonElement.style,
                        isDesktopView
                      ) +
                      getBackNextButtonCss(
                        StaticButtonElement.content.backButton.style
                      )
                    "
                    @click="backPage"
                    v-if="!StaticButtonElement.content.backButton.hideBackButton"
                  >
                    <span
                      :style="{
                        opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                        fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                      }"
                      >{{ StaticButtonElement.content.backButton.text }}</span
                    >
                  </button>
                  <button
                    style="z-index: 1000"
                    :style="
                      getCommonButtonCSS(
                        StaticButtonElement.style,
                        isDesktopView
                      ) +
                      getBackNextButtonCss(
                        StaticButtonElement.content.nextButton.style
                      )
                    "
                    @click="nextPage"
                  >
                  <span v-if="isLoadingNextButton || isLoadingFor=='products'"><b-spinner small></b-spinner></span>
                    <span v-else
                      :style="{
                        opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                        fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                      }"
                      >Next →</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Image Align Right -->
      <div
        class="px-0"
        v-show="showRightImage"
        :class="[
          {
            'col-12 col-lg-12 col-xs-12': !isDesktopView,
            'image-division col-6': isDesktopView,
          },
        ]"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div :class="isDesktopView ? 'image-division' : ''">
          <div :style="height100(isDesktopView)">
            <LazyImage
            :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) +
                getImageRotation(pageDesign.imageBlock.style) +
                getImageOpacity(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
              alt
            />
          </div>
        </div>
      </div>
  
      <!-- Show Logo Footer -->
    </div>
  </template>
  <script>
  
  import cssFunctionMixin from "../../../customize/mixin/cssFunction";
  import TranslationMixin from "../../../customize/mixin/TranslationMixin";
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import axios from 'axios'
  export default {
    props: [
      "isDesktopView",
      "animationType",
      "RoutePage",
      "getIsAnimationEnabled",
      "getAnimationSpeedinMillis",
      "questionId",
      "apiToken",
      "getSelectedPage",
      "quizId",
      "isLoadingFor",
      "planLimit"
    ],
    components:{
      LoaderMain:()=> import(/* webpackChunkName: "LoaderMain" */ '../../../../components/LoaderComps/LoaderMain.vue'),
    },
    mixins: [cssFunctionMixin,TranslationMixin],
    data() {
      return {
        alreadySelected:[],
        isLoadingNextButton:false,
        currentQuiz:{},
        isLoadingQuiz:false,
        activeSideBar: "pageStyle",
        pageDesign: {},
        count: 0,
        selectedOptions: [],
        showAlert: false,
        showAlertText: "",
        email: "",
        name: "",
        phone: "",
        date: "",
        website: "",
        organisation: "",
        address_1: "",
        address_2: "",
        city: "",
        zip_code: "",
        state: "",
        country: "",
        delayIncrement: 0.2, // The delay between each item
      };
    },
    methods: {
  //        async getQuestion(){
  //     this.isLoadingQuiz=true;
  //     const data = {
  //   "questionId":this.questionId.questionId,
  //   "email": localStorage.getItem("already_record_email") || this.$route.query.email,
  //   "quizId":this.quizId
  // }
  //         await axios.post('/api/custom/collection/loadQuestionPageDesign', data ).then((response)=>{
  //             this.currentQuiz=response.data.data[0];
  //             this.pageDesign=this.currentQuiz.pageDesign;
  //             this.selectedOptions= this.currentQuiz.selectedOption
  //             this.alreadySelected= [...this.selectedOptions]
  //             // this.ImageInputBlock=
  //         }).catch((e)=>{
  //           console.log(e)
  //            this.toast('An error has occurred');
  //           this.$emit('moveTo',1)
  //         });
  //         this.isLoadingQuiz=false
  //     },
  
      currentCounterValue(id) {
        let index = this.selectedOptions.findIndex((option) => option.id == id);
        if (index > -1) return this.selectedOptions[index].counterValue;
      },
      incrementCounter(id, operation) {
        let index = this.selectedOptions.findIndex((option) => option.id == id);
        if (index > -1) {
          if (operation === "increment") {
            if (this.currentCounterValue(id) < 4) {
              this.selectedOptions[index].counterValue += 1;
            } else {
              this.selectedOptions[index].counterValue = 4; // To prevent going beyond the range.
            }
          } else if (operation === "decrement") {
            if (this.currentCounterValue(id) > 1) {
              this.selectedOptions[index].counterValue -= 1;
            } else {
              this.selectedOptions[index].counterValue = 1; // To prevent going below the range.
            }
          }
        }
      },
      checkSelectedOptionForCounter(option) {
        let isSelectedOption = this.selectedOptions.some(
          (selected) => selected.id == option.id
        );
        return isSelectedOption;
      },
      backPage() {
        this.$emit('moveTo',1)
      },
      ...mapMutations([
        "jumpToPreviewPage",
        "PreviewPagesStack",
        "nextPageAnimation",
        "backPageAnimation",
      ]),
      ...mapActions([
        "goToNextPreviewPage",
        "goToBackPreviewPage",
        "updateSelectedOptionsObject",
        "createEndQuizPage",
      ]),
      getPageDesign() {
        let design =
          "text-align:" +
          this.pageDesign.pageStyles.textAlign +
          ";color:" +
          this.pageDesign.pageStyles.color +
          "; background:" +
          this.pageDesign.pageStyles.backgroundColor +
          "; width:" +
          100 +
          "%; height:" +
          this.pageDesign.pageStyles.height +
          "px;";
  
        return design;
      },
      getMultiplePageValues() {
        let getStartedDesignValues = {};
        if (this.getTotalPages && this.getSelectedPage) {
          getStartedDesignValues = this.getSelectedPage.pageDesign;
        }
        return getStartedDesignValues;
      },
      // function to find Logic Array Index relative to selected Options
      findLogicArrIndex(LogicArr, selectedOptions) {
        const parsedOptions = LogicArr.map((obj) => JSON.parse(obj.optionIdArr));
        const selectedIds = selectedOptions.map((option) => option.id);
        let index = -1;
  
        parsedOptions.some((obj, i) => {
          const hasMatchingId = obj.some(({ id }) => selectedIds.includes(id));
          if (hasMatchingId) {
            index = i;
            return true;
          }
        });
  
        return index;
      },
   toast(text){
        this.$toasted.show(this.translateCustomText(text), {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
      },
      translateCustomText(key){
          return this.translateToItalian(key,this.quizId)   
        },
      async nextPage() {
         this.isLoadingNextButton=true;
          if (this.selectedOptions.length > 0) {
            
            const optionIDs= this.selectedOptions.map(option=>option.id)
            const data = {
    "questionId":this.getSelectedPage.id,
    "email": localStorage.getItem("already_record_email") || this.$route.query.email,
    "optionId":optionIDs,
    api_token: this.apiToken,
  }
           await axios.post('/api/custom/collection/updateElthBrands',data).then(response=>{
             if(response.status==200)
                {
                  this.toast("Your store has been successfully updated.")
                this.$emit('gotoPage',{nav:'products',step:3})
                }
              else
              {
                this.toast("An error has occurred")
              }
            }).catch(error=>{this.toast("An error has occurred"); console.log(error)})
            
          } else {
              this.showAlertText = this.translateCustomTexts(
                "Select at least one option."
              );
            this.showAlert = true;
            setTimeout(
              function () {
                this.showAlert = false;
              }.bind(this),
              3000
            );
          }
            this.isLoadingNextButton=false;
        },
  
      createSelectedOptionArr() {
        const obj = new Object();
        obj.questionId = this.getSelectedPage.id;
        obj.isMasterQuestion = this.getSelectedPage.isMasterQuestion;
        obj.questionType = this.getSelectedPage.value;
        obj.selectedOption = this.selectedOptions;
        return obj;
      },
      //  createSelectedOptionObj(value){
      //       const obj = new Object();
      //           obj.questionId = this.getSelectedPage.id;
      //           obj.isMasterQuestion = this.getSelectedPage.isMasterQuestion;
      //           obj.questionType = this.getSelectedPage.value;
      //           obj.selectedOption = new Array;
      //           obj.selectedOption.push(value) ;
      //           return obj
      //   },
      updateSelectedAnswer(value) {
        const index = this.selectedOptions.findIndex(
          (option) => option.id == value.id
        );
        if (index > -1) {
          this.selectedOptions.splice(index, 1);
        } else {
          let isDisabled = this.limitSelectionCheckbox(value);
          if (isDisabled) {
            return;
          } else {
            this.selectedOptions.push(value);
          }
          //  this.selectedOptions.push(value)
        }
      },
      isSelectedOptionExist(value) {
        if (value) {
          const index = this.selectedOptions.findIndex(
            (option) => option.id == value.id
          );
          if (index > -1) {
            return true;
          } else {
            return false;
          }
        }
      },
      checkSelectedImage(value) {
        const alreadyExistBrand= this.alreadySelected.some(brand=>brand.id==value.id)
        const ValueIndex = this.selectedOptions.findIndex(
          (option) => option.id == value.id
        );

        if (ValueIndex > -1) {
          // this.selectedOptions.splice(ValueIndex, 1);
           
        
           
        
       
         if(alreadyExistBrand)
         {

           this.showAlertText = this.translateText(
                 "Unable to unselect the already chosen brand."
               );
               this.showAlert = true;
               setTimeout(
                 function () {
                   this.showAlert = false;
                 }.bind(this),
                 3000
               );
         }
         else
         {
          this.selectedOptions.splice(ValueIndex, 1);
         }
        } else {
          let isDisabled = this.limitSelectionCheckbox(value);
          if (isDisabled) {
            
            return;
          } else {
             if(this.planLimit>0 && !alreadyExistBrand &&  this.selectedOptions.length>=this.planLimit) 
         {
           this.showAlertText = this.translateText(
                 "Update your plan for more selection."
               );
               this.showAlert = true;
               setTimeout(
                 function () {
                   this.showAlert = false;
                 }.bind(this),
                 3000
               );
               return
         }
            this.selectedOptions.push(value);
          }
        }
        // if(this.$refs.checkbox[index].checked){
        //     this.$refs.checkbox[index].checked = false
        // }
        // else {
        //   this.$refs.checkbox[index].checked = true
  
        // }
      },
      IsSelectedImageExist(value) {
        const index = this.selectedOptions.findIndex(
          (option) => option.id == value.id
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      },
      ImageSrc(img) {
        // return img.startsWith('/http') ? img.substring(1) : img
        let newImage;
        if (img.startsWith("/http")) {
          newImage = img.substring(1);
        } else {
          newImage = img;
        }
        if (
          newImage.indexOf("http://") === 0 ||
          newImage.indexOf("https://") === 0
        ) {
          return newImage;
        } else {
          const baseURL = this.getEnv?.VUE_APP_API_BASE_URL??'';
          return baseURL + newImage.substring(1);
        }
      },
  
      limitSelectionCheckbox(checkbox) {
        let index = this.findOptionsIndex;
        let isDisabled;
        let isLimit =
          this.pageDesign.blocksArray[index].limitSelection &&
          this.pageDesign.blocksArray[index].limitSelection.limitSelectionValue;
  
        if (isLimit) {
          let limitType =
            this.pageDesign.blocksArray[index].limitSelection.limitSelectionType;
          if (limitType == "exact") {
            let limitValue =
              this.pageDesign.blocksArray[index].limitSelection.exactSelection;
            if (this.selectedOptions.length >= limitValue) {
              this.showAlertText = this.translateText(
                "Exceeded maximum selections!"
              );
              this.showAlert = true;
              setTimeout(
                function () {
                  this.showAlert = false;
                }.bind(this),
                3000
              );
              isDisabled = true;
            } else {
              isDisabled = false;
            }
          } else {
            let maxValue =
              this.pageDesign.blocksArray[index].limitSelection.maxRangeSelection;
            if (this.selectedOptions.length >= maxValue) {
              this.showAlertText = this.translateText(
                "Exceeded maximum selections!"
              );
              this.showAlert = true;
              setTimeout(
                function () {
                  this.showAlert = false;
                }.bind(this),
                3000
              );
              isDisabled = true;
            } else {
              isDisabled = false;
            }
          }
        } else {
          isDisabled = false;
        }
  
        if (typeof checkbox == "undefined") {
          return isDisabled;
        } else {
          return isDisabled && this.selectedOptions.indexOf(checkbox) === -1;
        }
      },
      UpdateDynamicText() {
        if (this.getSavedLeadID.full_name !== "") {
          this.name = this.getSavedLeadID.full_name;
        }
        if (this.getSavedLeadID.email !== "") {
          this.email = this.getSavedLeadID.email;
        }
        if (this.getSavedLeadID.phone_number !== "") {
          this.phone = this.getSavedLeadID.phone_number;
        }
        if (this.getSavedLeadID.date !== "") {
          this.date = this.getSavedLeadID.date;
        }
        if (this.getSavedLeadID.website !== "") {
          this.website = this.getSavedLeadID.website;
        }
        if (this.getSavedLeadID.organisation !== "") {
          this.organisation = this.getSavedLeadID.organisation;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address1") &&
          this.getSavedLeadID.address1 !== ""
        ) {
          this.address_1 = this.getSavedLeadID.address1;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address2") &&
          this.getSavedLeadID.address2 !== ""
        ) {
          this.address_2 = this.getSavedLeadID.address2;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "city") &&
          this.getSavedLeadID.city !== ""
        ) {
          this.city = this.getSavedLeadID.city;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "country") &&
          this.getSavedLeadID.country !== ""
        ) {
          this.country = this.getSavedLeadID.country;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "state") &&
          this.getSavedLeadID.state !== ""
        ) {
          this.state = this.getSavedLeadID.state;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "zip_code") &&
          this.getSavedLeadID.zip_code !== ""
        ) {
          this.zip_code = this.getSavedLeadID.zip_code;
        }
      },
    },
    computed: {
     getEnv(){
      // ...mapGetters(["getEnvConst"]),
      // getEnvConst
      return this.getEnvConst;
    },
      StaticButtonElement() {
        const index = this.pageDesign.blocksArray.findIndex(
          (x) => x.type == "button"
        );
        return this.pageDesign.blocksArray[index];
      },
     
      getMultiMax() {
        let index = this.findOptionsIndex;
        let maxFinalValue;
        let isLimit =
          this.pageDesign.blocksArray[index].limitSelection &&
          this.pageDesign.blocksArray[index].limitSelection.limitSelectionValue;
  
        if (isLimit) {
          let limitType =
            this.pageDesign.blocksArray[index].limitSelection.limitSelectionType;
          if (limitType == "exact") {
            let limitValue =
              this.pageDesign.blocksArray[index].limitSelection.exactSelection;
            maxFinalValue = parseInt(limitValue);
          } else {
            let maxValue =
              this.pageDesign.blocksArray[index].limitSelection.maxRangeSelection;
            maxFinalValue = parseInt(maxValue);
          }
        } else {
          maxFinalValue = false;
        }
  
        return maxFinalValue;
      },
      getMultiMin() {
        let index = this.findOptionsIndex;
        let minFinalValue;
        let isLimit =
          this.pageDesign.blocksArray[index].limitSelection &&
          this.pageDesign.blocksArray[index].limitSelection.limitSelectionValue;
  
        if (isLimit) {
          let limitType =
            this.pageDesign.blocksArray[index].limitSelection.limitSelectionType;
          if (limitType == "exact") {
            let limitValue =
              this.pageDesign.blocksArray[index].limitSelection.exactSelection;
            minFinalValue = parseInt(limitValue);
          } else {
            let minValue =
              this.pageDesign.blocksArray[index].limitSelection.minRangeSelection;
            minFinalValue = parseInt(minValue);
          }
        } else {
          minFinalValue = false;
        }
  
        return minFinalValue;
      },
      validateLimitOptions() {
        let index = this.findOptionsIndex;
        let isValid;
        let nonValidText = "";
        let isLimit =
          this.pageDesign.blocksArray[index].limitSelection &&
          this.pageDesign.blocksArray[index].limitSelection.limitSelectionValue;
  
        if (isLimit) {
          let limitType =
            this.pageDesign.blocksArray[index].limitSelection.limitSelectionType;
          if (limitType == "exact") {
            let limitValue =
              this.pageDesign.blocksArray[index].limitSelection.exactSelection;
  
            if (this.selectedOptions.length < limitValue) {
              isValid = false;
              nonValidText = "Please select more options.";
            } else {
              isValid = true;
              nonValidText = "";
            }
          } else {
            let minValue =
              this.pageDesign.blocksArray[index].limitSelection.minRangeSelection;
  
            if (this.selectedOptions.length < minValue) {
              isValid = false;
              nonValidText = "Please select more options.";
            } else {
              isValid = true;
              nonValidText = "";
            }
          }
        } else {
          isValid = true;
          nonValidText = "";
        }
  
        let obj = {
          valid: isValid,
          text: nonValidText,
        };
        return obj;
      },
      staticButton() {
        const index = this.pageDesign.blocksArray.findIndex(
          (x) => x.type == "button"
        );
        return this.pageDesign.blocksArray[index].staticButton;
      },
      ...mapGetters([
        "getUploadedImage",
        "getTotalPages",
        "getTotalPreviewPages",
        "getPreviewSettings",
        "getPreviewProgressBar",
        "getSavedLeadID",
        "getAnimationSetting",
        "translateText",
        "getFurnitureClientCounterData",
        "getTrackIkeaClientCounter",
        "getEnvConst"
      ]),
      // "getSkipQuestion"
      ProgressBarValues() {
        return this.getPreviewProgressBar;
      },
      getSkipQuestion() {
        return this.CurrentPage.skip_status;
      },
      CurrentPage() {
        return this.getSelectedPage;
      },
      IsImageAdded() {
        if (this.getImageSrc(this.pageDesign.imageBlock , this.isDesktopView) !== "" || this.getUploadedImage)
          return true;
        return false;
      },
      IsImageVariableWidthActive() {
        return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no"
          ? true
          : false;
      },
      imageAddedLayout() {
        if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
        if (!this.isDesktopView) return "col-12";
        return "col-12 col-xs-12 col-sm-6 col-md-6  ";
      },
      showLeftImage() {
        return (
          (this.isDesktopView &&
            this.pageDesign.imageBlock.style.displayDesktop &&
            this.pageDesign.pageStyles.imageAlign == "left") ||
          (!this.isDesktopView &&
            this.pageDesign.imageBlock.style.displayMobile &&
            (this.pageDesign.pageStyles.imageAlign == "left" ||
              this.pageDesign.pageStyles.imageAlign == "right"))
        );
      },
      showRightImage() {
        return (
          this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "right"
        );
      },
      findOptionsIndex() {
        const index = this.pageDesign.blocksArray.findIndex(
          (block) => block.type == "option"
        );
        return index;
      },
      getOptionsArr() {
        if (this.findOptionsIndex) {
          if (this.isOptionsShuffled) {
            const arrayCopy = [
              ...this.pageDesign.blocksArray[this.findOptionsIndex].options,
            ];
            for (let i = arrayCopy.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
            }
  
            return arrayCopy;
          } else {
            return this.pageDesign.blocksArray[this.findOptionsIndex].options;
          }
        }
        return [];
      },
      isOptionsShuffled() {
        return Object.prototype.hasOwnProperty.call(
          this.pageDesign.blocksArray[this.findOptionsIndex],
          "shuffleOptions"
        )
          ? this.pageDesign.blocksArray[this.findOptionsIndex].shuffleOptions
          : false;
      },
      isIkeaClientCounterQuestion() {
        return (
          this.getPreviewSettings.quizId ==
            (this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null) &&
          this.CurrentPage.name ==
            (this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUESTION_NAME??null)
        );
      },
  
      isIkeaClientTagQuestion() {
        let tagsQuestions = 
          this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[];
      
        return this.getPreviewSettings.quizId ==
          (this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null) &&
          tagsQuestions.some((data) => data.id == this.CurrentPage.id)
          ? true
          : false;
      },
      IkeaClientCounterValue() {
        if (this.isIkeaClientTagQuestion) {
          let currentTagQuestion =
            this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[].find((obj) => obj.id == this.CurrentPage.id);
  
          let tag = currentTagQuestion.type;
          let finalCounterValue =
            this.getFurnitureClientCounterData()[tag].counter;
          let currentCounterValue = this.getTrackIkeaClientCounter[tag].counter;
  
          return `(${currentCounterValue} / ${finalCounterValue})`;
        }
        return null;
      },
    },
    mounted() {
      this.pageDesign=this.getSelectedPage.pageDesign
       this.selectedOptions= this.getSelectedPage.selectedOption
        this.alreadySelected= [...this.selectedOptions]
      // this.getQuestion()
      this.UpdateDynamicText();
  
      if (this.isIkeaClientCounterQuestion) {
        let index = this.findOptionsIndex;
        if (index > -1) {
          let options = [...this.pageDesign.blocksArray[index].options];
          this.pageDesign.blocksArray[index].options = options.map((option) => {
            return { ...option, counterValue: 1 };
          });
        }
      }
  
      if (
        this.getPreviewSettings.quizId ==
        this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null
      ) {
        // Add Counter Values To text
        let tagsQuestions = this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[];
  
        if (tagsQuestions.some((data) => data.id == this.CurrentPage.id)) {
          // Remove Selected Answer
  
          this.pageDesign.blocksArray[this.findOptionsIndex].selectedOption = [];
        }
      }
    },
    created() {
      this.pageDesign = this.getMultiplePageValues();
      const OptionBlockIndex = this.findOptionsIndex;
      if (
        OptionBlockIndex &&
        this.getPreviewSettings.quizId !=
          (this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null)
      ) {
        this.selectedOptions =
          this.pageDesign.blocksArray[OptionBlockIndex].selectedOption;
      }
    },
    watch: {
      getTotalPages(newVal, oldVal) {
        if (oldVal.length < newVal.length) {
          this.count++;
        }
        this.pageDesign = this.getMultiplePageValues();
      },
    },
  };
  </script>
  
  <style scoped>
  .alert-text {
    padding: 4px 12px !important;
    margin: 0px;
    width: fit-content;
    border-radius: 6px !important;
  }
  
  /* DropdownStyles */
  .select-div >>> .multiselect .multiselect__tag {
    background: var(--dropdownHoverColor) !important;
  }
  .select-div >>> .multiselect .multiselect__tag-icon:after {
    color: #ffffff !important;
  }
  .select-div >>> .multiselect .multiselect__option--highlight,
  .select-div >>> .multiselect .multiselect__option--highlight:after {
    background: var(--dropdownHoverColor) !important;
  }
  
  .checkboxDiv input[type="checkbox"] {
    accent-color: var(--selectedCheckboxColor);
  }

  </style>