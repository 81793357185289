var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoadingQuiz)?_c('div',{staticClass:"mt-5",staticStyle:{"min-height":"300px"}},[_c('LoaderMain')],1):_c('div',{staticClass:"row position-relative m-0 p-0 w-100 big-div-quiz",staticStyle:{"z-index":"100"},style:(_vm.getPageDesign())},[(_vm.pageDesign.pageStyles.imageAlign == 'no')?_c('div',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView),staticClass:"bg lazyload",style:(_vm.getbackgroundImage(
_vm.IsImageAdded,
_vm.pageDesign.imageBlock,
_vm.pageDesign.pageStyles.imageAlign , _vm.isDesktopView
) + _vm.getBgDivStyles()),attrs:{"data-bg":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView))}}):_vm._e(),(_vm.showAlert)?_c('div',{staticClass:"AlertContainer"},[_c('div',{staticClass:"alert text-center fade show text-white alert-text",staticStyle:{"background":"#f27367","width":"fit-content"},attrs:{"role":"alert"}},[_c('span',[_c('i',{staticClass:"fa fa-exclamation-triangle mr-2",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.showAlertText))])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"px-0",class:[
        {
          'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
          'image-division col-6': _vm.isDesktopView,
        },
      ],style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView),class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView)),"alt":""}})],1)])]),_c('div',{class:[!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout],style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles, _vm.isDesktopView) +
        _vm.getScrollCSS(
          _vm.pageDesign.pageStyles,
          _vm.isDesktopView,
          _vm.pageDesign.imageBlock.style,
          _vm.pageDesign.pageType
        ) +
        _vm.calculateContentWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive,
          _vm.pageDesign.pageStyles
        ))},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"flex-end","height":"100%"}},[_c('div',{staticClass:"h-100",staticStyle:{"overflow":"hidden scroll"}},_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:'preview' + ind},[_c('div',{staticClass:"content",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style)),attrs:{"id":"preview"}},[(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                  _vm.getPaddingCss(pro.style) +
                  _vm.getMarginCss(pro.style, _vm.isDesktopView) +
                  _vm.getColorWithOpacity(pro.style) +
                  _vm.getTextRotate(pro.style) +
                  _vm.getLineHeight(pro.style) +
                  _vm.getTextAlign(pro.style) +
                  _vm.getFontStyle(pro.style,_vm.isDesktopView)),domProps:{"innerHTML":_vm._s(_vm.getTextValue(pro.content))}}):_vm._e(),(pro.type == 'option')?_c('div',[_c('div',[_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                      _vm.getTextAlign(pro.style))},[(_vm.isIkeaClientTagQuestion)?_c('div',{},[_c('p',{staticClass:"m-0 p-0",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.IkeaClientCounterValue)+" ")])]):_vm._e(),(pro.optionSetting.optionType === 'image')?_c('div',[_c('div',{staticClass:"row"},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('div',{key:index,staticClass:"d-flex flex-column",class:_vm.isDesktopView
                              ? `col-${12 / pro.OptionsPerRow}`
                              : `col-${12 / pro.OptionsPerMobile}`,style:(_vm.getDivAlignmentStyle(pro.style.textAlign) +
                            _vm.getSpaceBetweenRow(pro.style))},[(!checkbox.image)?_c('div',{staticClass:"ImageInputBlock position-relative",style:(_vm.getImageHeightWidth(pro.style, _vm.isDesktopView)),on:{"click":function($event){return _vm.checkSelectedImage(checkbox)}}},[_c('LazyImage',{staticStyle:{"cursor":"pointer","height":"100%","width":"100%"},style:(_vm.getBorderRadius(pro.style)),attrs:{"src":'/images/image_placeholder.png',"alt":""},on:{"click":function($event){return _vm.checkSelectedImage(checkbox)}}}),(_vm.IsSelectedImageExist(checkbox))?_c('div',{staticClass:"SelectedImageStyle",style:(_vm.getSelectedImageIconStyles(pro.style))},[_c('i',{staticClass:"fa fa-check-circle fa-2x",attrs:{"aria-hidden":"true"}})]):_vm._e()],1):_c('div',{staticClass:"ImageInputBlock position-relative",style:(_vm.getImageHeightWidth(pro.style, _vm.isDesktopView)),on:{"click":function($event){return _vm.checkSelectedImage(checkbox)}}},[_c('LazyImage',{staticStyle:{"cursor":"pointer","height":"100%","width":"100%"},style:(_vm.getBorderRadius(pro.style)),attrs:{"src":_vm.ImageSrc(checkbox.image),"alt":""},on:{"click":function($event){return _vm.checkSelectedImage(checkbox)}}}),(_vm.IsSelectedImageExist(checkbox))?_c('div',{staticClass:"SelectedImageStyle",style:(_vm.getSelectedImageIconStyles(pro.style))},[_c('i',{staticClass:"fa fa-check-circle fa-2x",attrs:{"aria-hidden":"true"}})]):_vm._e()],1),_c('div',[_c('p',{staticClass:"m-0 p-0 mt-2",style:(_vm.getColorCss(pro.style) +
                                _vm.getColorWithOpacity(pro.style) +
                                _vm.getFontStyle(pro.style))},[_vm._v(" "+_vm._s(checkbox.value)+" ")])]),(
                              _vm.isIkeaClientCounterQuestion &&
                              _vm.checkSelectedOptionForCounter(checkbox)
                            )?_c('div',{staticClass:"counter-parent mx-2 pt-2"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('button',{staticStyle:{"width":"40px !important","height":"40px !important","border-radius":"50% !important","max-width":"40px !important","max-height":"40px !important","min-width":"40px !important","min-height":"40px !important","border":"none"},style:([{ backgroundColor: '#CDE4E3' }]),on:{"click":function($event){return _vm.incrementCounter(checkbox.id, 'decrement')}}},[_vm._v(" - ")]),_c('span',{staticStyle:{"width":"30px","border":"none"}},[_vm._v(_vm._s(_vm.currentCounterValue(checkbox.id)))]),_c('button',{staticStyle:{"width":"40px !important","height":"40px !important","border-radius":"50% !important","max-width":"40px !important","max-height":"40px !important","min-width":"40px !important","min-height":"40px !important","border":"none"},style:([{ backgroundColor: '#CDE4E3' }]),on:{"click":function($event){return _vm.incrementCounter(checkbox.id, 'increment')}}},[_vm._v(" + ")])])]):_vm._e()])}),0)]):_vm._e()])])]):_vm._e()])])}),0),_c('div',[_c('div',{staticClass:"content",style:(_vm.getHeightWidth(_vm.StaticButtonElement.style))},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex",style:(_vm.getBackNextButtonPosition(
                    _vm.StaticButtonElement,
                    _vm.CurrentPage.value
                  ))},[(!_vm.StaticButtonElement.content.backButton.hideBackButton)?_c('button',{staticStyle:{"z-index":"1000"},style:(_vm.getCommonButtonCSS(
                      _vm.StaticButtonElement.style,
                      _vm.isDesktopView
                    ) +
                    _vm.getBackNextButtonCss(
                      _vm.StaticButtonElement.content.backButton.style
                    )),on:{"click":_vm.backPage}},[_c('span',{style:({
                      opacity: `${_vm.StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${_vm.StaticButtonElement.content.backButton.style.fontSize}px`,
                    })},[_vm._v(_vm._s(_vm.StaticButtonElement.content.backButton.text))])]):_vm._e(),_c('button',{staticStyle:{"z-index":"1000"},style:(_vm.getCommonButtonCSS(
                      _vm.StaticButtonElement.style,
                      _vm.isDesktopView
                    ) +
                    _vm.getBackNextButtonCss(
                      _vm.StaticButtonElement.content.nextButton.style
                    )),on:{"click":_vm.nextPage}},[(_vm.isLoadingNextButton || _vm.isLoadingFor=='products')?_c('span',[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',{style:({
                      opacity: `${_vm.StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${_vm.StaticButtonElement.content.nextButton.style.fontSize}px`,
                    })},[_vm._v("Next →")])])])])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"px-0",class:[
        {
          'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
          'image-division col-6': _vm.isDesktopView,
        },
      ],style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView),class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView)),"alt":""}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }